<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.80382 15.3C3.65391 15.3 0.146159 10.5703 0 2.70001H3.08057C3.18176 8.47659 5.45279 10.9234 7.25166 11.4279V2.70001H10.1525V7.68199C11.9289 7.46758 13.795 5.19731 14.4246 2.70001H17.3253C16.8419 5.77749 14.8181 8.04776 13.379 8.98109C14.8181 9.73785 17.123 11.718 18 15.3H14.8069C14.1211 12.9036 12.4123 11.0496 10.1525 10.7973V15.3H9.80382Z"
    />
  </svg>
</template>
